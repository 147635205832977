import { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
const Home = lazy(() => import('../../pages/Home'))
const Maps = lazy(() => import('../../pages/Maps'))
const Token = lazy(() => import('../../pages/Token'))
const Mint = lazy(() => import('../../pages/Mint'))
const Collections = lazy(() => import('../../pages/Collections'))
const Product = lazy(() => import('../../pages/Product'))
const HP = lazy(() => import('../../pages/HP'))

const NFTShirtRedirect = () => <Redirect to='/product/6634922147863' />

const Pages = () => {
  return (
    <Switch>
      <Suspense
        fallback={
          <h1 className='text-white w-100 text-center text-lg-h4'>
            ...loading
          </h1>
        }
      >
        <Route path='/' exact component={HP} />
        <Route path='/map' exact component={Maps} />
        <Route path='/collection/:contractAddress' exact component={Home} />
        <Route path='/mint' exact component={Collections} />
        <Route path='/mint/:contractAddress' exact component={Mint} />
        <Route
          path='/collection/:contractAddress/:tokenId'
          exact
          component={Token}
        />
        <Route path='/product/:productId' exact component={Product} />
        <Route path='/nft-shirt' component={NFTShirtRedirect} />
      </Suspense>
    </Switch>
  )
}

export default Pages
