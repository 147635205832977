import { formatEther } from '@ethersproject/units'
import { BigNumber } from 'ethers'

const formatter = new Intl.NumberFormat('en-us', {
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
})

export const formatBalance = balance =>
  formatter.format(parseFloat(formatEther(balance ?? BigNumber.from('0'))))

export const handlize = str => str.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-')
