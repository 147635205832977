import { getAccountPath } from 'ethers/lib/utils'
import { useEffect } from 'react'
import { useState, createContext } from 'react'

export const CartContext = createContext({
  cart: [],
  addToCart: item => {},
  updateQuantity: (itemId, quantity) => {},
  removeFromCart: itemId => {},
  toggleSidebar: () => {},
})

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([])
  const [subtotal, setSubtotal] = useState(0)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [checkout, setCheckout] = useState(
    JSON.parse(sessionStorage.getItem('mint_checkout'))
  )

  useEffect(() => {
    let s = 0
    cart.forEach((item, i) => {
      s +=
        item.variant.quantity *
        Number(item.product.priceRangeV2.maxVariantPrice.amount)
    })

    setSubtotal(s)
  }, [cart])

  useEffect(() => {
    if (checkout) {
    }
    sessionStorage.setItem('mint_checkout', JSON.stringify(checkout))
  }, [checkout])

  const getCartCount = () => {
    let q = 0
    cart.forEach(item => {
      q = q + item.variant.quantity
    })
    return q
  }
  const cartQuantity = cart.length > 0 ? getCartCount() : 0

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }

  const addToCart = item => {
    setCart([...cart, item])
    // setSubtotal(
    //   subtotal + Number(item.product.priceRangeV2.maxVariantPrice.amount)
    // )
  }

  const updateQuantity = (index, quantity) => {
    setCart(
      cart.map((item, i) => {
        if (i === index) {
          return { ...item, variant: { ...item.variant, quantity } }
        }
        return item
      })
    )
    // let updatedSubtotal = 0
    // cart.forEach(item => {
    //   updatedSubtotal +=
    //     item.variant.quantity *
    //     Number(item.product.priceRangeV2.maxVariantPrice.amount)
    // })
    // setSubtotal(updatedSubtotal)
  }

  const removeFromCart = index => {
    setCart(cart.filter((item, i) => i !== index))
    // let updatedSubtotal = 0
    // cart.forEach((item, i) => {
    //   if (i !== index) {
    //     updatedSubtotal +=
    //       item.variant.quantity *
    //       Number(item.product.priceRangeV2.maxVariantPrice.amount)
    //   }
    // })
    // setSubtotal(updatedSubtotal)
  }
  return (
    <CartContext.Provider
      value={{
        cart,
        sidebarOpen,
        subtotal,
        cartQuantity,
        checkout,
        setCheckout,
        addToCart,
        updateQuantity,
        removeFromCart,
        toggleSidebar,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider
