import React, { useContext } from 'react'
import { CartContext } from '../../context/Cart'
import Web3Button from '../Web3Button'
import { TiShoppingCart } from 'react-icons/ti'
import { Link, useLocation } from 'react-router-dom'
import './index.scss'

const HeaderBar = ({ title }) => {
  const location = useLocation()

  const { sidebarOpen, toggleSidebar, cart, cartQuantity } =
    useContext(CartContext)

  if (location.pathname === '/') {
    return null
  }

  return (
    <>
      <div className='d-flex w-100 align-items-center justify-content-between py-4 px-4 bg-black'>
        <div className='d-flex align-items-end h-100'>
          <Link
            to='/'
            className='text-lg-h3 my-0 no-underline text-white text-center py-1'
          >
            {title}
          </Link>
        </div>
        <div className='d-flex align-items-center'>
          <div className='position-relative cursor-pointer'>
            {cart.length > 0 && (
              <div className='cart-count text-white text-lg-b3 text-primary'>
                {cartQuantity}
              </div>
            )}
            <TiShoppingCart
              className='text-white ml-2'
              size={'1.5rem'}
              onClick={toggleSidebar}
            />
          </div>
          <Web3Button />
        </div>
      </div>
    </>
  )
}

export default HeaderBar
