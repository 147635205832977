import React from 'react'
import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import HeaderBar from './components/HeaderBar'
import Pages from './components/Pages'
import CartProvider from './context/Cart'

function App() {
  return (
    <CartProvider>
      <Router>
        <div className=' vw-100'>
          <HeaderBar title='minters.studio' />
          <Pages />
        </div>
      </Router>
    </CartProvider>
  )
}

export default App
