import { FaEthereum } from 'react-icons/fa'
import {formatBalance} from '../../../../utils/'

const AccountView = ({account , balance}) => {

  const formatted = formatBalance(balance)

  return (
    <div className='w-100 d-flex px-5'>
      <div className='w-25 d-flex flex-column'>
        <h4 className='text-lg-h4'>LOREM ACCOUNT</h4>
        <div className='d-flex align-items-center mt-2'>
          <h2 className='text-lg-h2 text-white'>{0.05}</h2>
          <span className='text-primary ml-2'>
            <FaEthereum size='2.5rem' />
          </span>
        </div>
      </div>
      <div className="w-70 d-flex flex-column ml-3">
        <h5 className="text-lg-h5 text-white  mb-4">0x9E3B93373821C26fd5dc88Cb925F48B856299f28</h5>
        <button className="btn btn-outline-white text-lg-b1 w-100 primary-hover rounded-pill" onClick={()=> console.log('hey cunt')}>DEPOSIT FUNDS</button>
      </div>
    </div>
  )
}

export default AccountView


