import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import './custom.scss'
import { DAppProvider, Mainnet } from '@usedapp/core'

const dappConfig = {
  readOnlyChainId: 4,
  readOnlyUrls: {
    [Mainnet.chainId]:
      'https://rinkeby.infura.io/v3/3d9b8212a8ca4031955922df673e01c0',
  },
}

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={dappConfig}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
