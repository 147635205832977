import Web3Modal from 'web3modal'
import React, { useEffect, useState } from 'react'
import { useEthers, shortenAddress, useLookupAddress } from '@usedapp/core'
import { ethers } from 'ethers'
import { signMessage } from '../../utils/eth'
import AccountModal from '../AccountModal'
import './index.scss'
import { Link } from 'react-router-dom'

const Web3Button = () => {
  const { account, activate, deactivate } = useEthers()
  const ens = useLookupAddress()

  const [showModal, setShowModal] = useState(false)
  const [activateError, setActivateError] = useState('')
  const { error } = useEthers()
  useEffect(() => {
    if (error) {
      setActivateError(error.message)
    }
  }, [error])

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: 'Metamask',
          description: 'Connect with the provider in your Browser',
        },
        package: null,
      },
      // when we ant to add magic-link or maybe a different version!?
      //   walletconnect: {
      //     package: WalletConnectProvider,
      //     options: {
      //       bridge: 'https://bridge.walletconnect.org',
      //       infuraId: '3d9b8212a8ca4031955922df673e01c0',
      //     },
      //   },
    }

    const web3Modal = new Web3Modal({
      providerOptions,
    })
    try {
      const provider = await web3Modal.connect()
      const signer = new ethers.providers.Web3Provider(provider).getSigner()
      const signedMessages = await signMessage(signer)
      if (signMessage) {
        await activate(provider)
        setActivateError('')
      }
    } catch (error) {
      setActivateError(error.message)
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <>
      {showModal && (
        <AccountModal showModal={showModal} handleClose={handleClose} />
      )}
      <div className='d-flex align-items-center'>
        {/* {account && (
          <Link
            to='/mint'
            className='btn btn-outline-primary rounded-pill py-2 px-4'
          >
            Mint
          </Link>
        )} */}
        <div className='text-black mx-2'>{activateError}</div>
        {account ? (
          <>
            <button
              className='btn btn-black text-white rounded-pill border-right-0 account-pill text-lg-b2'
              onClick={() => setShowModal(true)}
            >
              {ens ? ens : shortenAddress(account).toString().toUpperCase()}
            </button>
            <button
              className='btn btn-black text-white rounded-pill connect-pill py-2 px-4 text-lg-b1'
              onClick={() => deactivate()}
            >
              DISCONNECT
            </button>
          </>
        ) : (
          <button
            className='btn btn-black text-white rounded-pill py-2 px-4 text-lg-b1 connect-pill'
            onClick={activateProvider}
          >
            CONNECT
          </button>
        )}
      </div>
    </>
  )
}

export default Web3Button
