import { Modal, Button } from "react-bootstrap"
import { formatEther } from '@ethersproject/units'
import { BigNumber } from 'ethers'
import { useEthers, getExplorerAddressLink, useEtherBalance } from '@usedapp/core'
import { FaEthereum } from 'react-icons/fa'
import {CgCloseO} from 'react-icons/cg'
import WalletView from "./components/WalletView"
import './index.css'
import AccountView from "./components/AccountView"

const AccountModal = ({showModal, handleClose}) => {

    const formatter = new Intl.NumberFormat('en-us', {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      })
      
      const formatBalance = (balance) =>
        formatter.format(parseFloat(formatEther(balance ?? BigNumber.from('0'))))


        const { account, chainId } = useEthers()
        const balance = useEtherBalance(account)
        if (account && chainId) {

  return (
    <Modal animation={true} size="lg" contentClassName='account-modal'  aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleClose} centered >
    <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-start" style={{height: '400px'}}>
          <div className="w-100 d-flex align-items-center justify-content-end">
            <CgCloseO size='2rem' onClick={handleClose} />
          </div>
          <div className="w-100 h-80 d-flex flex-column align-items-center justify-content-around">
          <WalletView balance={balance} account={account} />
          <AccountView />
          </div>
          {/* <div className="h-90 d-flex flex-column align-items-center justify-content-center">
        <h3 className="text-lg-h4">Address: {account}</h3>
        <a 
         href={getExplorerAddressLink(account, chainId)} target="_blank" rel="noopener noreferrer">
        View on Etherscan
        </a>
        <p className="text-lg-p1 d-flex align-items-center">{`here's your balance brah: ${balance && formatBalance(balance)}`}<FaEthereum className="ml-1"/></p>

        </div> */}
        </div>
        </Modal.Body>
  </Modal>
  )
        }
}

export default AccountModal
